.eg_consultingInfo {
  display: block;
  margin-left: auto;
}
.eg_consultingInfo .eg_phoneIcon {
  fill: #5a89b7;
  margin: 0.7em;
}
.eg_consultingInfo .eg_textContainer {
  text-align: right;
}
.eg_consultingInfo .eg_textContainer > button {
  padding: 0;
  margin: 0;
}
.eg_consultingInfo .eg_infoIcon {
  margin: 0.7em;
}
.eg_consultingInfo .eg_telNumber {
  color: #023a80;
  cursor: pointer;
}
.eg_modalContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.eg_modalContainer h3 {
  margin: 0;
}
@media screen and (max-width: 480px) {
  .eg_modalContainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .eg_modalContainer img {
    margin-bottom: 0.7em;
  }
}
@media screen and (min-width: 480px) {
  .eg_modalContainer {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.eg_modalContainer .eg_imageContainerModal {
  margin-right: 1em;
  min-width: 200px;
  min-height: 200px;
}
.eg_modalContainer .eg_phoneComponent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.4em;
}
.eg_modalContainer .eg_phoneComponent div p {
  margin: 0;
}
.eg_modalContainer .eg_phoneComponent div p.eg_phoneNumber {
  margin: 0.7em 0 0.7em 0;
  font-size: 12px;
}
.eg_modalContainer .eg_phoneComponent div p.eg_subline {
  font-size: 12px;
}
.eg_modalContainer .eg_textContainerModal ol {
  list-style: decimal;
}
.eg_modalContainer .eg_textContainerModal ol li {
  list-style-position: outside;
  margin-left: 1.2em !important;
}
.eg_forceDownload {
  width: 100%;
}
.eg_forceDownload h2 {
  margin-bottom: 1em;
}
.eg_forceDownload .eg_headingText {
  margin-bottom: 1em;
}
.eg_forceDownload .eg_checkboxWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.eg_forceDownload .eg_checkboxWrapper h1 {
  text-align: center;
  margin-bottom: 0.5em;
}
.eg_forceDownload .eg_checkboxWrapper p {
  margin-top: 1em;
}
.eg_forceDownload .eg_buttonWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.eg_forceDownload .eg_hinweis strong {
  font-weight: bold;
}
.eg_hr {
  background: #dddddd;
  height: 1px;
  border: 0;
  margin: 20px 0;
}
.eg_hr--no-margin-bottom {
  margin-bottom: 0;
}
