.esc_simple-table th {
    text-align: left !important;
}

.esc_simple-table td {
    text-align: left !important;
    vertical-align: top !important;
    background-color: inherit !important;
}

.esc_simple-table tbody>tr:nth-child(even) {
    background-color: #f2f2f2 !important;
}

.modal-tooltip {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
}

.modal-tooltip > .ee_tooltip-icon > .ee_tooltip-icon__button {
    padding: 1px !important;
    width: 20px !important;
    height: 20px !important;
}

.modal-first-tooltip {
    display: block !important;
}

.feepage-center-text {
    text-align: center;
}

.feepage-bold-font {
    font-weight: bold;
}

.feepage-header {
    margin-top: 4em;
    margin-bottom: 2em;
}

.feepage-price {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    position: relative;
}

.feepage-wrapper {
    line-height: 30px;
    justify-content: center;
}

.feepage-col {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
}

.feepage-details-keyword {
    color: #000000;
    font-weight: bold;
}

.feepage-zahlweise {
    margin-top: 2em;
}

.feepage-zahlweise-buttons {
    margin-top: 25px;
}

.feepage-zahlweise-button {
    margin: 0 5px 15px 5px;
    min-width: 10em;
}

.feepage-pricing-tooltip {
    margin-bottom: 2em;
}

.feepage-pricing-calculation {
    position: relative;
    top: 1px;
}

.feepage-pricing-small-text {
    color: grey;
    font-size: 0.8em;
}

.feepage-pricing-float {
    float: right;
}

.feepage-detailed-pricing {
    font-size: 0.9em;
    margin-bottom: 1em;
}

.modal-header {
    color: #8e0038;
}

.modal-section-header {
    margin-bottom: 0 !important;
}

.details-grid {
    max-width: 500px;
    margin: 0 auto;
}

.details-auto-margin {
    margin: auto;
}

.details-bottom-margin {
    margin-bottom: 1em;
}
