.removeBorderBottom {
    border-bottom: 0;
}

.headline-variant {
    text-align: center;
}


.headline-price {
    padding-top: 0;
    text-align: center;
}

@media (min-width: 768px) {
    .headline-variant {
        text-align: left;
    }

    .headline-price {
        text-align: right;
        padding-top: 0.5em;
    }
}
