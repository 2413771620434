.insurancebegin-restriction-spacer{
    font-size: 0.8rem;
    height: 30px;
}

.insurancebegin-restriction-message{
    font-size: 0.8rem;
    padding: 1.5em 0;
    display: flex;
    margin: 0 40px;
    flex-direction: column;
    justify-content: center;
}

