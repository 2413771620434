.footer {
    display: flex;
    clear: both;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
}

div.esc_text-link.back-button {
    margin-top: 1.25em;
    font-size: 0.85em;
    color: #737373;
    font-weight: bold;
    cursor: pointer;
}

.footer-consultingInfo {
    margin-top: 1.25em;
}

.footer-requestOffer-button {
    margin-top: 1.25em;
    font-weight: bold !important;
}

.footer-next-button {
    margin-top: 1.25em;
}