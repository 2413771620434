.taxhint__link {
  color: #bf1528;
  cursor: pointer;
}

.taxhint__list {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.5rem;
}

.taxhint__bullet {
  display: flex;
  margin: 8px 0;
}

.taxhint__bullet-number {
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  font-size: 0.75em;
  padding: 8px;
  margin: 2px 10px 0 0;
  background: #000000;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.taxhint__check-wrapper {
  display: flex;
}

.taxhint__check-wrapper .ee_checkbox__label {
  padding: 0 1em 0 0 !important;
}
