@font-face {
    font-family: 'FS Me';
    src: url('/fonts/FSMeWeb-Regular.woff');
}

@font-face {
    font-family: 'FS Me';
    src: url("/fonts/FSMeWeb-Bold.woff");
    font-weight: bold;
}

@font-face {
    font-family: 'FS Me';
    src: url("/fonts/FSMeWeb-Italic.woff");
    font-style: italic;
}

@font-face {
    font-family: 'FS Me';
    src: url("/fonts/FSMeWeb-BoldItalic.woff");
    font-weight: bold;
    font-style: italic;
}

.ee_form-footer__content {
    justify-content: center;
}

.ee_button {
    box-shadow: none !important;
}

.grid-cell-vertical-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: .9em;
}

.kfo-custom-button {
    width: 300px;
}

.kfo-custom-button[data-disabled="false"]:hover {
    background: #BF1528 !important;
    border-color: #BF1528 !important;
    color: #FFFFFF !important;
}

.kfo-custom-button[data-disabled="true"], .kfo-custom-button[data-disabled="true"]:hover {
    color: #C8C8C8 !important;
    border-color: #C8C8C8 !important;
    background-color: #FFFFFF !important;
    cursor: default !important;
}

#kfo-root {
    padding: 0 24px;
    margin: auto;
    max-width: 864px;
}